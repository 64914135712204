<template>
  <div class="mx-auto my-6">
    <h2 class="my-4">
      {{ userType === "Cliente" ? 'Mis Direcciones' : 'Sucursales'}}
      <v-btn
        class="mx-4 py-4"
        fab
        small
        color="primary"
        @click="toogleShowForm"
      >
        <v-icon dark>
          {{ this.showForm ? 'mdi-minus' : 'mdi-plus' }}
        </v-icon>
      </v-btn>

    </h2>
    <v-row
      justify="center"
      class="px-2 mx-4 my-6"
    >
      <v-col 
        cols="12" 
        sm="12" 
        lg="6"
      >
      <v-card
        v-if="showForm"
        class="px-4 py-4"
        max-width="600"
      >
        <p class="text-primary">
          {{ this.edit ? 'Modificar' : 'Nueva' }} Dirección:
        </p>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-row>
            <v-col
              v-if="userType === 'Distribuidor'"
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="newAddress.nombre_sucursal"
                label="Nombre Sucursal"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="newAddress.calle"
                label="Calle"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="newAddress.numero_exterior"
                label="Número Exterior"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="newAddress.numero_interior"
                label="Número Interior"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="newAddress.codigo_postal"
                label="Código Postal"
                :rules="[rules.required]"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="newAddress.colonia"
                label="Colonia"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="newAddress.ciudad"
                label="Ciudad"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="newAddress.estado"
                label="Estado"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="newAddress.numero_contacto"
                label="Número de Contacto"
                :rules="[rules.required]"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="userType === 'Cliente'"
              cols="12"
            >
              <v-checkbox
                class="my-0 py-0"
                v-model="newAddress.predeterminado"
                label="Predeterminado"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            :disabled="!valid"
            color="primary"
            @click="sendAddress"
          >
            {{ buttonAction }}
          </v-btn>
          <v-btn
            color="primary"
            @click="cleanForm"
          >
            limpiar campos 
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-col>
      <v-col 
        v-if="showForm"
        cols="12"
        sm="12"
        lg="6"
      >
        <customer-delivery-address 
          :customerAddress="newAddress" 
          :showCustomerAddress="showCustomerAddress"
          @updateAddress="updateAddressByMap"
        />
      </v-col>
    </v-row>
    <v-row class="my-4" justify="center">
      <sucursal-card
        v-if="userType === 'Distribuidor'"
        :sucursales= "sucursales"
        @sucursalEdit="editSucursal"
        @deleteSucursal="deleteSucursal"
      ></sucursal-card>
      <direccion-card
        v-if="userType === 'Cliente'"
        :userAddresses="addresses"
        :selectedAddress="selectedAddress"
        :selectedUserAdd="selectedUserAdd"
        @addressEdit="addEdit"
        @deleteAdd="deleteAddress"
        @selectedAdd="emitSelectedAdd"
      ></direccion-card>
    </v-row>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { ApiAuth } from '@/api_utils/axios-base'
import store from '@/store'
import { validationMixin } from '@/mixins/formsValidations.js'

export default {
  mixins: [validationMixin],
  props: {
    selectedAddress: Boolean,
    sucursales: Array,
    distriId: Number,
  },
  data() {
    return {
      newAddress: {
        nombre_sucursal: null,
        calle: null,
        numero_exterior: null,
        numero_interior: null,
        codigo_postal: null,
        colonia: null,
        ciudad: null,
        estado: null,
        predeterminado: false,
        latitud: null,
        longitud: null
      },
      edit: false,
      addressModify: null,
      addresses: null,
      selectedUserAdd: null,
      showForm: false,
      valid: true,
      userType: "",
      suscursalModify: null,
      showCustomerAddress: false,
    }
  },
  components: {
    sucursalCard: () => import('@/components/utils/Cards/Sucursal'),
    direccionCard: () => import('@/components/utils/Cards/Direccion'),
    CustomerDeliveryAddress: () => import('@/components/utils/Maps/CustomerDeliveryAddress'),
  },
  created() {
    this.getUserExtraData()
  },
  computed: {
    ...mapState(['mapsLoader']),
    ...mapState('usuario', ['userData']),
    buttonAction() {
      return this.edit ? "Modificar" : "Agregar"
    },
    userAddresses() {
      return this.addresses
    }
  },
  methods: {
    ...mapMutations('usuario', ['updateSelectedAddress']),
    async getUserExtraData() {
      this.userType = this.userData.tipo_usuario
      if(this.userType === "Cliente") {
        try {
          let response = await ApiAuth.get(`/personas/api/cliente/?usuario_id=${this.userData.id}`) 
          const responseData = await response.data
          if (responseData.length) {
            const addressesResponse = await responseData[0].direcciones_entrega
            const clienteId = await responseData[0].id

            this.addresses = addressesResponse
            this.clienteId = clienteId
            if(this.addresses.length) {
              if (this.addresses.length === 1) {
                this.emitPredeterminadaDireccion(this.addresses[0])
              } else {
                let uDirecciones = this.addresses.find(dir => dir.predeterminado === true)
                if (uDirecciones) {
                  this.emitPredeterminadaDireccion(uDirecciones)
                }
              }
              /*let that = this
              const addressesFull = [...this.addresses]
              const addressWithoutPosition = addressesFull.filter(address => !address.latitud && !address.longitud)
              console.log('addressWithoutPosition: ', addressWithoutPosition);
              if (addressWithoutPosition.length) {
                const geocoder = new google.maps.Geocoder()
                await loader.load().then(async () => {
                  for(const address of addressWithoutPosition) {
                    this.geocodeByAddress(geocoder, address, that.addresses, true)
                  }
                })
                this.addresses = addressesResponse
              }*/
            }
          }
        } catch(error) {
          console.error("error getting addresses", error)
        }
      }
    },
    async addEdit(address) {
      let {id, fecha_elaboracion, estatus_sistema, ...currentAddress} = address
      this.addressModify = address
      this.newAddress = currentAddress
      this.edit = true
      this.showForm = true
      this.showCustomerAddress = true
    },
    editSucursal(sucursal) {
      let {id, nombre_sucursal, autor, distribuidor, estatus_sistema, fecha_elaboracion, ...currentDireccion} = sucursal
      let newCurrentDireccion = currentDireccion.direccion
      newCurrentDireccion.nombre_sucursal = sucursal.nombre_sucursal
      this.suscursalModify = sucursal
      this.newAddress = newCurrentDireccion
      this.edit = true
      this.showForm = true
    },
    cleanForm() {
      Object.keys(this.newAddress).forEach(k => this.newAddress[k]= null)
      this.newAddress.predeterminado = false
      this.edit = false
      this.showCustomerAddress = false
    },
    async sendAddress() {
      if(!this.$refs.form.validate()) {
        console.error("validation form error")
        return
      } 
      if(this.userType === "Cliente") {
        let that = this
        
        
        await this.mapsLoader.load().then(async () => {
          const geocoder = new google.maps.Geocoder()
          this.geocodeByAddress(geocoder, that.newAddress, null, false)
        })
        
        if(this.edit) {
          try {
            // store.commit('setLoading', true)
            if(this.newAddress.predeterminado === true) {
              this.setPredeterminadoFalse()
              localStorage.setItem('userAddress', JSON.stringify(this.newAddress))
              this.updateSelectedAddress(this.newAddress)
              // store.commit('usuario/updateSelectedAddress', this.newAddress)
            }
            
            let response = await ApiAuth.put(`/personas/api/direccion/${this.addressModify.id}/`,
              this.newAddress
            )
            this.getUserExtraData()
            this.showForm = false
            // setTimeout(() => { store.commit('setLoading', false)}, 500)
            this.cleanForm()

          } catch(error) {
            console.log("error editing address", error)
          }
        } else {
          try {
            store.commit('setLoading', true)
            if(this.newAddress.predeterminado === true) {
              this.setPredeterminadoFalse()
              localStorage.setItem('userAddress', JSON.stringify(this.newAddress))
              this.updateSelectedAddress(this.newAddress)
            }
            
            let responseNewAddress = await ApiAuth.post('/personas/api/direccion/', this.newAddress)
            const responseDataNewAddress = await responseNewAddress.data
            // console.log('ResponseDatanewAddres: ', responseNewAddress);
            let response = await ApiAuth.post('/personas/agregar-direccion-cliente',
              {
                id_direccion: responseDataNewAddress.id,
                id_cliente: this.clienteId
              }
            )
            const responseDir = await response.data
            this.getUserExtraData()
            this.showForm = false
            setTimeout(() => { store.commit('setLoading', false)}, 500)
            this.cleanForm()
          } catch(error) {
            console.error("error creating new address", error)
          }
        }

      } else if(this.userType === "Distribuidor") {
        if(this.edit) {
          try {
            store.commit('setLoading', true)
            let {nombre_sucursal, ...direccion} = this.newAddress

            let responseModDireccion = await ApiAuth.put(`/personas/api/direccion/${direccion.id}/`,
              direccion
            )
            let response = await ApiAuth.put(`/personas/api/sucursal/${this.suscursalModify.id}/`,
              { nombre_sucursal: this.newAddress.nombre_sucursal }
            )
            store.commit('setLoading', false)

          } catch(error) {
            console.error("error editing sucursal", error)
          }
        } else {
          try {
            store.commit('setLoading', true)
            this.newAddress.id_distribuidor = this.distriId
            let responseNewSucursal = await ApiAuth.post('/personas/crear-sucursal', this.newAddress)
            store.commit('setLoading', false)

          } catch(error) {
            console.error("error creating new sucursal", error)
          }
        }
        this.cleanForm()
      }

    },
    async deleteAddress(addressId) {
      let response = await ApiAuth.delete(`/personas/api/direccion/${addressId}/`)
      this.getUserExtraData()
    },
    async deleteSucursal(sucursalId) {
      // no elimina la direccion con la que se creo, se elimina esa direccion?
      let response = await ApiAuth.delete(`/personas/api/sucursal/${sucursalId}/`)
      this.$parent.getSucursales()
    },
    emitSelectedAdd(address) {
      this.$emit('dirSeleccionada',address )
      this.selectedUserAdd = address.id
    },
    emitPredeterminadaDireccion(dir) {
      this.$emit('dirPredeterminada', dir)
      this.selectedUserAdd = dir.id
    },
    setPredeterminadoFalse() {
      let uDirecciones = this.addresses.find(dir => dir.predeterminado === true)
      if(uDirecciones) {
        uDirecciones.predeterminado = false
        ApiAuth.put(`/personas/api/direccion/${uDirecciones.id}/`, uDirecciones)
          .then(resp => {
            console.log("dir predeterminado limpio")
          })
      } else {
        return
      }
    },
    toogleShowForm() {
      this.showForm = !this.showForm
    },
    updateAddressByMap(selectedAddress) {
      this.newAddress = {...selectedAddress}
    },
    async geocodeByAddress(geocoder, address, addressesResponse, updateCoordinates) {
      const addressStr = `${address.calle},${address.numero_exterior},${address.colonia},${address.codigo_postal},${address.estado},${address.ciudad}`
      await geocoder.geocode(
        {
          'address': addressStr
        }, 
        async function(results, status) {
          if (status === 'OK') {
            // console.log('Results: ', results);
            address.latitud = results[0].geometry.location.lat()
            address.longitud = results[0].geometry.location.lng()
            if (updateCoordinates) {
              const actualizacionRes = await ApiAuth.post('/personas/actualizar-coordenadas-direccion', 
                {
                  id_direccion: address.id,
                  longitud: address.longitud,
                  latitud: address.latitud
                })
              const actualizacionData = await actualizacionRes.data
              // console.log('Actualizacion respuesta: ', actualizacionData);
              if (actualizacionRes.status === 200) {
                // console.log("Se debe actualizar el array de direcciones: ", addressesResponse)
                const indexToUpdate = addressesResponse.findIndex(ad => ad.id === actualizacionData.Direccion)
                // console.log('index to update: ', indexToUpdate);
                if (indexToUpdate !== -1) {
                  addressesResponse[indexToUpdate].latitud = actualizacionData.latitud
                  addressesResponse[indexToUpdate].longitud = actualizacionData.longitud
                }
              }
            }
          }
        })
    },
  }
}
</script>
